import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AvatarComponent } from './avatar/avatar.component';
import { HomeComponentEnglish } from './home-en/home-en.component';
import { AvatarComponentEnglish } from './avatar-en/avatar-en.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeComponentEnglish,
    AvatarComponent,
    AvatarComponentEnglish
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
